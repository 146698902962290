import React from 'react';
import '../css/fenykepek.css';
import Carousel from '../components/carousel';
import {Link} from 'react-router-dom';
import "../css/background.css";

function Fenykepek() {
    return (
        <div className="fenykepek">
            <div className="gombok">
                <div className="kaszni-kezdolap">
                    <Link className="button" to="/">Kezdőlap</Link>
                </div>
                <div className="kaszni-leiras">
                    <Link className="button" to="/leiras">Leírás</Link>
                </div>
            </div>
            <div className="row">
                <div className="card">
                    <img className="card-img-top" src={require("../kepek/kaszni.png")}
                         alt="kaszni"/>
                    <Link to="/kaszni" className="button">Kaszni</Link>
                </div>
                <div className="card">
                    <img className="card-img-top" src={require("../kepek/muszerfal_tabla.png")}
                         alt="interior"/>
                    <Link to="/interior" className="button">Kárpit</Link>
                </div>
                <div className="card">
                    <img className="card-img-top" src={require("../kepek/futomu01.jpg")}
                         alt="futomu"/>
                    <Link to="/suspension" className="button">Futómű</Link>
                </div>
                <div className="card">
                    <img className="card-img-top" id="hamarosan" src={require("../kepek/hamarosan.png")} alt="hamarosan"/>
                    <Link to="#/movies" className="button">Videok</Link>
                </div>
            </div>
            <div className="carousel" style={{width: "75%"}}>
                <Carousel/>
            </div>
        </div>

    )
        ;
}

export default Fenykepek;