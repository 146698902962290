import React from 'react';
import MyNavbar from "../components/navbar";
import '../css/szelepFedelMove.css';
import '../css/fooldal.css';

function Fooldal() {
    return (
        <div className="body">
            <div className="fooldal">
                <div className="fooldal-navbar>">
                    <MyNavbar/>
                </div>
                <img className="img-flui d skoda" src={require('../kepek/skodahdr1.png')}
                     style={{width: "45%", position: "absolute"}}
                     alt={'skoda110'}/>
                <img className='img-fluid szelepfedel' src={require('../kepek/szelepfedel.png')}
                     alt={'szelepfedel'}/>
            </div>
        </div>

    );
}

export default Fooldal;
