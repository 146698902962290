import React from 'react';
import './App.css';


import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Fenykepek from './pages/fenykepek';
import Fooldal from "./pages/fooldal";
import Kaszni from "./pages/kaszni";
import Interior from "./pages/interior";
import Suspension from "./pages/suspension";
import Leiras from "./pages/leiras";
import Movies from "./pages/movies";


function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Fooldal/>}/>
                <Route path="/fenykepek/" element={<Fenykepek/>}/>
                <Route path="/kaszni" element={<Kaszni/>}/>
                <Route path="/interior" element={<Interior/>}/>
                <Route path="/suspension" element={<Suspension/>}/>
                <Route path="/leiras" element={<Leiras/>}/>
                <Route path="/movies" element={<Movies/>}/>
            </Routes>
        </Router>
    );
}

export default App;
